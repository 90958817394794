import React from "react";

const ListDetails = () => {
	return (
		<>
			<h1>Hello from List Details</h1>
		</>
	);
};

export default ListDetails;
